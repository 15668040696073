@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


:root {
  --font-base: "DM Sans", sans-serif;
  --font-heading: "Bebas Neue", cursive;

  --primary-color: #edf2f8;
  --secondary-color: #f29d8c;
  
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --dark-gray-color: #434343;
  --gray-color: #eaeaec;
  --brown-color: #46364a;
  --white-color: #ffffff;

  --pastel-red-color: #ec917f;
  --pastel-orange-color: #f1b282;
  --pastel-yellow-color:  #e1eb9e;;
  --pastel-green-color: #93e4c8;
  --pastel-blue-color: #95bfec;
  --pastel-purple-color: #c3b7f1;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}