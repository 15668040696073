.app__lessonslearned {
  padding: 6rem 2rem 0 2rem;
}

.app__lessonslearned-desc {
  padding: 1rem;
}

.app__lessonslearned-img {
  width: 80%;
  margin: auto;
  object-position: 50% 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }
  @media screen and (min-width: 1100px) {
    width: 50%;
  }
}