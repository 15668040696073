#home {
  position: relative;
  background: url('../../assets/prism.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 65% 50%;
  background-clip: border-box;
  

  .app_wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {

  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 8rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 17rem;
  }
  @media screen and (max-width: 1200px) {
    padding-top: 8rem;
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width:100%;
  display:flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp, .tag-cmp {
    padding: 1rem 2rem;
    border: varr(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    p {
      width: 100%;
      font-size: 1.5em;
      color: var(--dark-gray-color);
      font-size: 1.3rem;
      margin-bottom: .3rem;
      margin-top: 1rem;
    }

     .head-text {
      font-family: var(--font-heading);
      font-size:5rem;
      line-height:100%;
      letter-spacing: .2rem;
      color:var(--white-color);
      position:relative;
      background: linear-gradient(45deg, var(--pastel-red-color), var(--pastel-orange-color), var(--pastel-yellow-color), var(--pastel-green-color), var(--pastel-blue-color), var(--pastel-purple-color), var(--pastel-red-color));
      background-clip: text;
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
      background-size:400% 400%;
      animation: gradient 6s ease infinite;
      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
     }
    }

  .app__tagline {
    flex-direction: column;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
    font-size: .6em;
    font-weight: 500;
    color:rgba(67, 67, 67, 0.179);
    margin-top: .5rem;

  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: var(--white-color);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    // &:hover {
    //   box-shadow: 0 0 25px rgba(255, 162, 0, 0.394) 
    // }

    img {
      width: 60%;
      height: 60%;
    }
  }

  div:nth-child(1) {
    width: 100px;
    height: 100px;

    &:hover {
      box-shadow: 0 0 25px var( --pastel-red-color);
    }

  }
  div:nth-child(2) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;

    &:hover {
      box-shadow: 0 0 25px var(--pastel-blue-color); 
    }
  }
  div:nth-child(3) {
    width: 70px;
    height: 70px;

    &:hover {
      box-shadow: 0 0 25px var(--pastel-green-color)
    }
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }
    div:nth-child(2) {
      width: 300px;
      height: 300px;
    }
    div:nth-child(3) {
      width: 120px;
      height: 120px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

    img {
      width: 100%;
      object-fit: contain;
      z-index: 1;
    }

    @media screen and (max-width: 1200px) {
      margin: 2rem 0;
    }
    @media screen and (min-width: 800px) {
      width: 60%;
    }
}
