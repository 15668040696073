.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px ) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      
      height: 320px;
   
    }
  }

  @media screen and (min-width: 769px) {
    width: 140px;
    padding: 0;
    margin: 1rem;
    

    img {
      height: 122px;
    }
  }

  @media screen and (min-width: 900px) {
    width: 190px;
    padding: 0;
    margin: 1rem;
    

    img {
      height: 170px;
    }
  }
}

.about-text {
  margin-top: 1.3rem;

  @media screen and (min-width: 2000px) {
    width: 1500px;
    padding: 1rem;
    margin: 1rem;
  }

  @media screen and (max-width: 1999px) {
    width: 900px;
    padding: 1rem;
    margin: 1rem;
  }

  @media screen and (max-width: 1024px) {
    width: 600px;
    padding: 1rem;
    margin: 1rem;
  }

  @media screen and (max-width: 769px) {
    width: 500px;
    padding: 1rem;
    margin: 1rem;
  }

  @media screen and (max-width: 500px) {
    width: 300px;
    padding: 1rem;
    margin: 1rem;
  }

}

#head-text-about {
  margin: 2rem 1.5rem 0 1.5rem;

  @media screen and (max-width: 1024px) {
    width: 500px;
  }

  @media screen and (max-width: 480px) {
    width: 300px;
  }
}